import * as THREE from "three";
import "./style.css";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";

// lmao hey wassup

let camera, scene, renderer;
let logoUniforms;
let nMouse = [0, 0],
  mouse = [0, 0];
let starGeometry;

init();
drawStars();
animate();

function init() {
  const container = document.getElementById("container");
  const canvas = document.getElementById("canvas");

  camera = new THREE.PerspectiveCamera(
    31,
    window.innerWidth / window.innerHeight,
    0.1,
    2000
  );
  camera.position.z = 1100;

  scene = new THREE.Scene();

  //mouse input
  canvas.addEventListener("mousemove", (event) => {
    mouse[0] = ((event.clientX / canvas.clientWidth) * 2 - 1) * 0.04;
    mouse[1] = ((event.clientY / canvas.clientHeight) * 2 - 1) * 0.04;
  });
  canvas.addEventListener("mouseout", (event) => {
    mouse[0] = 0;
    mouse[1] = 0;
  });
  canvas.addEventListener("touchmove", (event) => {
    mouse[0] =
      ((event.touches[0].clientX / canvas.clientWidth) * 2 - 1) * -0.04;
    mouse[1] =
      ((event.touches[0].clientY / canvas.clientHeight) * 2 - 1) * -0.04;
  });
  canvas.addEventListener("touchend", (event) => {
    mouse[0] = 0;
    mouse[1] = 0;
  });

  // starfield background
  // const geometry = new THREE.PlaneGeometry(
  //   canvas.clientWidth,
  //   canvas.clientHeight
  // );
  // starUniforms = {
  //   time: { value: 1.0 },
  //   uResolution: { value: [canvas.clientWidth, canvas.clientHeight] },
  // };
  // let material = new THREE.ShaderMaterial({
  //   uniforms: starUniforms,
  //   vertexShader: document.getElementById("starsVS").textContent,
  //   fragmentShader: document.getElementById("starsFS").textContent,
  //   visible: !detectMob(),
  // });
  // material.transparent = true;
  // let mesh = new THREE.Mesh(geometry, material);
  //scene.add(mesh);

  //logo
  logoUniforms = {
    uTexture: {
      type: "t",
      value: new THREE.TextureLoader().load("assets/images/logo_blur.png"),
    },
    uMouse: { value: nMouse },
    time: { value: 1.0 },
  };
  const logoMaterial = new THREE.ShaderMaterial({
    vertexShader: document.getElementById("logoVS").textContent,
    fragmentShader: document.getElementById("logoFS").textContent,
    uniforms: logoUniforms,
    transparent: true,
  });

  const geometry = new THREE.PlaneGeometry(250, 250);
  //material = new THREE.MeshBasicMaterial({ color: 0x00ff00, wireframe: false });
  let mesh = new THREE.Mesh(geometry, logoMaterial);
  mesh.position.y += 50.0;
  scene.add(mesh);

  renderer = new THREE.WebGLRenderer({ canvas });
  renderer.setPixelRatio(window.devicePixelRatio);
  container.appendChild(renderer.domElement);

  onWindowResize();
  window.addEventListener("resize", onWindowResize);
}

// https://stackoverflow.com/questions/25850121/how-can-i-prevent-scaling-object-in-three-js-on-window-resize
function onWindowResize() {
  var tanFOV = Math.tan(((Math.PI / 180) * camera.fov) / 2);
  var windowHeight = window.innerHeight;
  camera.aspect = window.innerWidth / window.innerHeight;
  camera.fov =
    (360 / Math.PI) * Math.atan(tanFOV * (window.innerHeight / windowHeight));

  //starUniforms["uResolution"].value = [window.innerWidth, window.innerHeight];

  camera.updateProjectionMatrix();
  renderer.setSize(window.innerWidth, window.innerHeight);
}

function animate() {
  nMouse[0] += (mouse[0] - nMouse[0]) * 0.3; //intensity
  nMouse[1] += (mouse[1] - nMouse[1]) * 0.3;
  logoUniforms["time"].value = performance.now() / 1000;
  logoUniforms["uMouse"].value = nMouse;

  // starUniforms["time"].value = performance.now() / 1000;
  animateStars();
  requestAnimationFrame(animate);
  renderer.render(scene, camera);
}

function drawStars() {
  starGeometry = new THREE.BufferGeometry();
  const vertices = [];

  const star = new THREE.TextureLoader().load("assets/images/sparkle.png");

  for (let z = -1000; z < 1000; z += 25) {
    const x = Math.random() * 1000 - 500;
    const y = Math.random() * 1000 - 500;

    vertices.push(x, y, z);
    scene.add;
  }
  starGeometry.setAttribute(
    "position",
    new THREE.Float32BufferAttribute(vertices, 3)
  );
  var starMaterial = new THREE.PointsMaterial({
    size: 13,
    sizeAttenuation: true,
    map: star,
    alphaTest: 0.5,
    transparent: true,
    depthWrite: false,
  });
  starMaterial.color.setHSL(1, 0, 1);

  var particles = new THREE.Points(starGeometry, starMaterial);
  scene.add(particles);
}

function animateStars() {
  const starSpeed = 0.6;
  const positions = starGeometry.attributes.position.array;
  for (let i = 0; i < positions.length; i += 3) {
    const v = new THREE.Vector3(
      positions[i],
      positions[i + 1],
      positions[i + 2]
    );
    positions[i] = v.x;
    positions[i + 1] = v.y;
    positions[i + 2] = v.z + starSpeed;

    if (positions[i + 2] > 1100) {
      positions[i + 2] -= 2100;
    }
  }

  starGeometry.attributes.position.needsUpdate = true;
}

// function detectMob() {
//   const toMatch = [
//     /Android/i,
//     /webOS/i,
//     /iPhone/i,
//     /iPad/i,
//     /iPod/i,
//     /BlackBerry/i,
//     /Windows Phone/i,
//   ];

//   return toMatch.some((toMatchItem) => {
//     return navigator.userAgent.match(toMatchItem);
//   });
// }
